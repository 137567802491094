import React from 'react'
import useBookingPage from "services/store/BookingPage";

const PageStep = ({ step = 1 }) => {
  const { profile } = useBookingPage();
  return (
    <div id="pStep">
      <div className="bStep" data-step={step}>
        {profile.ConsultSelected === 'doctor' ? (
          <>
            <div className="step"></div>
            <div className="step"></div>
            <div className="step"></div>
            {/* <div className="step"></div> */}
          </>
        ) : (
          <>
            <div className="step"></div>
            <div className="step"></div>
            {/* <div className="step"></div> */}
          </>
        )}
      </div>
    </div>
  )
}

export default PageStep